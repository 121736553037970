

.homepage-productivity-flex-row{
    margin-bottom: 25px;
    padding-left:50px;
    padding-right: 50px;
    padding-bottom: 20px;
}

.homepage-card{
    /* margin-left:50px;
    margin-bottom: 20px;
    margin-top: 20px; */
    margin: 20px;
}

.need-padding{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 90px;
    padding-right: 90px;
}